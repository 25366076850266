import React from 'react';
import EncryptComponent from "./EncryptComponent"
import Settings from '../../../Settings/Settings'
import Typography from '@mui/material/Typography';
const encryptionPageTexts = {
    en: {
        title: "Lexie Encryption",
        title1: "We are suggesting the next problem",
        paragraphOne: "Bytes are composed of 8 bits. One bit is represented by either 0 or 1. " +
            "One byte has 8 bits, so a byte can be respresented like this: 11001100. So 1 byte has elements belonging " +
            "to a set of {0,1}.",
        paragraphTwo: "In order to encrypt data we can assign the bits to another set of data like this:",
        paragraphThree: " 0 can belong to every element of {1,2,3,4,5,6,7,8,9} and every bit represented by 1 can belong " +
            "to a set of  data {1,2,3,4,5,6,7,8,9}, as long as there is no intersection between these sets of data: if " +
            "bit 1 is represented by the value 2 then all the 0 bits representation cannot have the value 2 assigned.",
        paragraphFour: "Example one",
        paragraphFive: "0 ∈ {1,3,5,7,9} and 1 ∈ {2,4,6,8}",
        paragraphSix: "Example two",
        paragraphSeven: "0 ∈ {2} and 1 ∈ {1,3,4,5,6,7,8,9}",
        paragraphEight: "By this assumption the initial byte (11001100) can now look like this:",
        paragraphNine: "Example one:",
        paragraphTen: "24136857",
        paragraphEleven: "Example two:",
        paragraphTwelve: "13224522",
        paragraphThirteen: "In order to add more complexity to the encryption we can add to the bytes encryption a " +
        "number of 8 figures (between 10000000 and 99999999)",
        paragraphFourteen: "Example one:",
        paragraphFivteen: "24136857 + 12345678 = 36482535",
        paragraphSixteen: "Example two:",
        paragraphSeventeen: "1322452267 + 12345678 = 1334797945",
        paragraphEighteen: "The complete encryption will not assign the bytes values in the assigned bit order " +
            "but it will assign them randomly for every created bit",
        title2: "Practical example",
    },
    ro: {
        title: "Criptarea Lexie",
        title1: "Propunem urmatoarea problema",
        paragraphOne: "Octeti sunt compusi din 8 biti. Un bit este reprezentat de cifrele 0 sau 1. " +
            "Un octet are 8 biti, asadar un octet poate fi reprezentat in acest fel: 11001100. Un octet are elmente " +
            "apartinand multimii {0,1}.",
        paragraphTwo: "Pentru a putea cripta datele putem compune octetii in urmatorul fel:",
        paragraphThree: " 0 poate apartine oricarui element din multimea {1,2,3,4,5,6,7,8,9} si fiecare bit reprezentat " +
            "de 1 poate apartine unuia din elementele din multimea {1,2,3,4,5,6,7,8,9}, atat timp cat valorile bitilor " +
            "nu se intersecteaza : daca elementele apartinand lui 1 sunt reprezentate de valoarea 2 atunci elementele"  +
            "bit-ului 0 nu pot contine valoarea 2",
        paragraphFour: "Exemplul unu:",
        paragraphFive: "0 ∈ {1,3,5,7,9} si 1 ∈ {2,4,6,8}",
        paragraphSix: "Exemplul doi:",
        paragraphSeven: "0 ∈ {2} si 1 ∈ {1,3,4,5,6,7,8,9}",
        paragraphEight: "Pornind de la aceasta premiza octetul 11001100 poate arata in urmatorul fel:",
        paragraphNine: "Exemplul unu:",
        paragraphTen: "24136857",
        paragraphEleven: "Exemplul doi:",
        paragraphTwelve: "13224522",
        paragraphThirteen: "Pentru a adauga mai multa complexitate criptarii putem adauga un numar compus din 8 cifre"
        + " minim (intre 10000000 and 99999999)",
        paragraphFourteen: "Exemplul unu:",
        paragraphFivteen: "24136857 + 12345678 = 36482535",
        paragraphSixteen: "Exemplul doi:",
        paragraphSeventeen: "1322452267 + 12345678 = 1334797945",
        paragraphEighteen: "Criptarea completa nu va contine octeti formati din elemente aranjate in ordinea din " +
        "multimile  existente ci aceste valori vor fi compuse cu elemente de pe pozitii aleatorii",
        title2: "Exemplu practic",
    }

}

export default function AboutPage (props) {
    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const componentMethods = {
        ...props.componentMethods,
    }
    return [
        (<Typography align="justify" variant="h3" paragraph={true} component="h3">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].title}  </Typography>),
        // (<Typography align="justify" variant="h4" paragraph={true} component="h4">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].title1}  </Typography>),
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphOne}</Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphTwo} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphThree} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphFour} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphFive} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphSix} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphSeven} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphEight} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphNine} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphTen} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphEleven} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphTwelve} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphThirteen} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphFourteen} </Typography>,
        <Typography align="justify" variant="body1" component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphFivteen} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphSeventeen} </Typography>,
        <Typography align="justify" variant="body1" paragraph={true} component="p">  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].paragraphEighteen} </Typography>,
        <Typography align="justify" variant="h4" component="h4" paragraph={true}>  {encryptionPageTexts[componentConstants.urlParams.language ? componentConstants.urlParams.language : Settings.defaultLanguage].title2} </Typography>,
    ]
}